(function ($, undefined) {

    'use strict';

    $(document)

        .on('click', '#favorite-filter a[data-href]', function (evt) {
            evt.preventDefault();

            var method = $(this).data('method')
              , href   = $(this).data('href')
              , action = $(this).data('action')
            ;

            if ('add' == action || 'update' == action) {
                $('#favorite-filter form.title')
                    .removeClass('hide')
                    .attr('action', href)
                ;
            } else if ('remove' == action) {
                $.ajax({
                  url: href,
                  method: method,
                  cache: false,
                }).done(function() {
                    $('#favorite-filter').load(Routing.generate('admin_favorite_filter_show', {'context': $('#favorite-filter').data('context')}));
                });
            } else {
                $.ajax({
                  url: href,
                  method: method,
                  cache: false,
                }).done(function() {
                    // $('#favorite-filter').load(Routing.generate('admin_favorite_filter_show', {'context': $('#favorite-filter').data('context')}));
                    window.location.href=location.protocol + "//" + location.host + location.pathname + location.search;
                });
            }
        })
        .on('submit', '#favorite-filter form', function (evt) {
            evt.preventDefault();

            $.ajax({
              url: $(this).attr('action'),
              method: 'POST',
              data: $(this).serialize(),
              cache: false,
            }).success(function(data) {
                if ('ok' !== data.status) {
                    $('#favorite-filter').parents('.container').prepend('<div class="alert alert-danger alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>'+
                            data.message
                        +'</div>')
                } else {
                    $('#favorite-filter').load(Routing.generate('admin_favorite_filter_show', {'context': $('#favorite-filter').data('context')}))
                }
            });
        })

    ;

})(jQuery);
